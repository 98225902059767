//import logo from './logo.svg';
import './App.css';
import { SocialIcon } from 'react-social-icons';

function App() {
  return (
    <div className="App">
      <div className="App-body">
        
        <p class="greeting">
          <div><code>hi my name is seby</code></div> <span class="blinking-cursor">|</span>
        </p>
        
        <p>
          <SocialIcon url="https://github.com/rowbawts" bgColor="#696969" fgColor="white" target="_blank" className="social zoom"/>
          <SocialIcon url="https://twitter.com/ibuprofiend" fgColor="white" target="_blank" className="social zoom"/>
          <SocialIcon url="https://instagram.com/okseby" fgColor="white" target="_blank" className="social zoom"/>
          <SocialIcon url="https://discord.com/users/100001682317926400" fgColor="white" target="_blank" className="social zoom"/>
          <SocialIcon url="https://twitch.tv/okseby" fgColor="white" target="_blank" className="social zoom"/>
        </p>
        
      </div>
    </div>
  );
}

export default App;
